import { useStaticQuery, graphql } from "gatsby";
import { ArticleRaw } from "../utils/interfaces";
import { normalizeCategories } from "../utils/utils";

const activeEnv =
  process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || "development";

const getCategories = graphql`
  query Categories {
    published: allFile(
      filter: {
        sourceInstanceName: { eq: "articles" }
        childMdx: { frontmatter: { status: { eq: "published" } } }
      }
    ) {
      group(field: childMdx___frontmatter___categorySlug) {
        totalCount
        categorySlug: fieldValue
        nodes {
          id
          childMdx {
            frontmatter {
              title
              date
              author
              category
              categorySlug
              slug
              keywords
              banner {
                id
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            excerpt(pruneLength: 300)
          }
        }
      }
    }
    all: allFile(filter: { sourceInstanceName: { eq: "articles" } }) {
      group(field: childMdx___frontmatter___categorySlug) {
        totalCount
        categorySlug: fieldValue
        nodes {
          id
          childMdx {
            frontmatter {
              title
              date
              author
              category
              categorySlug
              slug
              keywords
              banner {
                id
                childImageSharp {
                  id
                  gatsbyImageData(
                    placeholder: BLURRED
                    formats: [AUTO, WEBP, AVIF]
                  )
                }
              }
            }
            excerpt(pruneLength: 300)
          }
        }
      }
    }
  }
`;

export const useCategories = () => {
  const { all, published } = useStaticQuery(getCategories);
  const categories: {
    categorySlug: string;
    nodes: ArticleRaw[];
  }[] = activeEnv === "development" ? all.group : published.group;

  return normalizeCategories(categories);
};
