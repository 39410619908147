import React from "react";
import { Link } from "gatsby";

import { LayoutMain } from "../layouts/layout-main/layout-main";
import { useCategories } from "../hooks/use-categories";

const Categories = () => {
  const categories = useCategories();

  return (
    <LayoutMain isBlogPost={false} title="Categories">
      <article className="page-content">
        <h1 className="page-title">Article Categories</h1>
        <ul>
          {categories.map((category) => {
            return (
              <li key={category.categorySlug}>
                <Link to={`/category/${category.categorySlug}`}>
                  {category.category}
                </Link>
                <ul>
                  {category.articles.map((article) => (
                    <li>
                      <Link to={`/article/${article.slug}`}>
                        {article.title}
                      </Link>
                    </li>
                  ))}
                </ul>
              </li>
            );
          })}
        </ul>
      </article>
    </LayoutMain>
  );
};

export default Categories;
